import React from 'react';

const ReactNext = ({ className }: { className?: string }) => (
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13.2891 18.6189H20.8644V19.2181H13.9835V23.728H20.4541V24.3272H13.9835V29.2785H20.9433V29.8777H13.2891V18.6189ZM21.543 18.6189H22.3479L25.9147 23.5703L29.5603 18.6189L34.519 12.3296L26.3723 24.0906L30.5703 29.8777H29.7339L25.9147 24.611L22.0796 29.8777H21.259L25.4885 24.0906L21.543 18.6189ZM30.8702 19.2181V18.6189H39.5029V19.2181H35.5259V29.8777H34.8315V19.2181H30.8702Z"
      fill="#FCFCFD"
    />
    <path
      d="M3.8125 18.6187H4.68051L16.6499 36.4436L11.7035 29.8774L4.53847 19.4702L4.50691 29.8774H3.8125V18.6187Z"
      fill="#FCFCFD"
    />
    <path
      d="M39.7483 29.0982C39.8918 29.0982 39.9966 28.9888 39.9966 28.8474C39.9966 28.706 39.8918 28.5966 39.7483 28.5966C39.6064 28.5966 39.5 28.706 39.5 28.8474C39.5 28.9888 39.6064 29.0982 39.7483 29.0982ZM40.4306 28.4384C40.4306 28.8558 40.7329 29.1285 41.1738 29.1285C41.6433 29.1285 41.927 28.8474 41.927 28.3593V26.6409H41.5487V28.3576C41.5487 28.6286 41.4119 28.7733 41.1704 28.7733C40.9542 28.7733 40.8073 28.6387 40.8022 28.4384H40.4306ZM42.4219 28.4165C42.4489 28.8508 42.812 29.1285 43.3525 29.1285C43.9301 29.1285 44.2916 28.8373 44.2916 28.3728C44.2916 28.0076 44.0855 27.8056 43.5856 27.6895L43.317 27.6238C42.9995 27.5498 42.8712 27.4505 42.8712 27.2771C42.8712 27.0583 43.0705 26.9153 43.3694 26.9153C43.6531 26.9153 43.8491 27.055 43.8845 27.2788H44.2527C44.2308 26.8698 43.8693 26.582 43.3745 26.582C42.8425 26.582 42.4878 26.8698 42.4878 27.3024C42.4878 27.6592 42.6888 27.8712 43.1296 27.9739L43.4437 28.0496C43.7663 28.1254 43.9082 28.2365 43.9082 28.4216C43.9082 28.637 43.6852 28.7935 43.3812 28.7935C43.0553 28.7935 42.8289 28.6471 42.7968 28.4165H42.4219Z"
      fill="#FCFCFD"
    />
  </svg>
);

export default ReactNext;
